import {createSlice} from '@reduxjs/toolkit';
import {LanguageDetailsDto} from 'src/shared/services/cms/languages/dto/language-details-dto';

interface LanguagesInitialState {
  languageDetails?: LanguageDetailsDto;
  languagesList?: Array<LanguageDetailsDto>;
  isLanguagesListLoading?: boolean;
}

const initialState: LanguagesInitialState = {
  languageDetails: undefined,
  languagesList: [],
  isLanguagesListLoading: false,
};

const languagesSlice = createSlice({
  name: 'languages',
  initialState: initialState,
  reducers: {
    setLanguageDetails: (state, action) => {
      state.languageDetails = action.payload;
    },
    setLanguagesList: (state, action) => {
      state.languagesList = action.payload;
    },
    setIsLanguagesListLoading: (state, action) => {
      state.isLanguagesListLoading = action.payload;
    },
  },
});

export const {setLanguageDetails, setLanguagesList, setIsLanguagesListLoading} =
  languagesSlice.actions;

export default languagesSlice.reducer;
