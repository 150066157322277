import {createSlice} from '@reduxjs/toolkit';
import {CategoryDetailsDto} from 'src/shared/services/cms/categories/dto/details-category-dto';

interface CategoriesInitialState {
  categoryDetails?: CategoryDetailsDto;
  categoriesList?: Array<CategoryDetailsDto>;
  isCategoriesListLoading?: boolean;
}

const initialState: CategoriesInitialState = {
  categoryDetails: undefined,
  categoriesList: [],
  isCategoriesListLoading: false,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: initialState,
  reducers: {
    setCategoryDetails: (state, action) => {
      state.categoryDetails = action.payload;
    },
    setCategoriesList: (state, action) => {
      state.categoriesList = action.payload;
    },
    setIsCategoriesListLoading: (state, action) => {
      state.isCategoriesListLoading = action.payload;
    },
  },
});

export const {
  setCategoryDetails,
  setCategoriesList,
  setIsCategoriesListLoading,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
