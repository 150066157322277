import {combineReducers} from 'redux';
import authSlice from './auth/auth-slice';
import dataGridSlice from './data-grid/data-grid-slice';
import rolesManagementSlice from './roles-management/roles-management-slice';
import usersManagementSlice from './users-management/users-management-slice';
import googleMapSlice from './google-map/google-map-slice';
import notificationSlice from './notifications/notification-slice';
import caregiversSlice from './caregivers/caregivers-slice';
import categoriesSlice from './cms/categories/categories-slice';
import subcategorySlice from './cms/subcategory/subcategory-slice';
import servicesSlice from './cms/services/services-slice';
import additionalServiceSlice from './cms/additional-service/additional-service-slice';
import clientsSlice from './clients/clients-slice';
import careRecipientsSlice from './care-recipients/care-recipients-slice';
import bookingSlice from './booking/booking-slice';
import casesSlice from './cases/cases-slice';
import ageGroupSlice from './cms/age-group/age-group-slice';
import certificatesSlice from './cms/certificates/certificates-slice';
import languagesSlice from './cms/languages/languages-slice';

export let reducer = combineReducers({
  auth: authSlice,
  dataGrid: dataGridSlice,
  rolesManagement: rolesManagementSlice,
  usersManagement: usersManagementSlice,
  googleMap: googleMapSlice,
  notifications: notificationSlice,
  caregivers: caregiversSlice,
  clients: clientsSlice,
  categories: categoriesSlice,
  subcategory: subcategorySlice,
  services: servicesSlice,
  additionalService: additionalServiceSlice,
  certificates: certificatesSlice,
  languages: languagesSlice,
  careRecipients: careRecipientsSlice,
  booking: bookingSlice,
  cases: casesSlice,
  ageGroup: ageGroupSlice,
});
