import {createSlice} from '@reduxjs/toolkit';
import {AgeGroupDetailsDto} from 'src/shared/services/cms/age-group/dto/age-group-details-dto';

interface AgeGroupInitialState {
  ageGroupDetails?: AgeGroupDetailsDto;
  ageGroupList?: Array<AgeGroupDetailsDto>;
  isAgeGroupListLoading?: boolean;
}

const initialState: AgeGroupInitialState = {
  ageGroupDetails: undefined,
  ageGroupList: [],
  isAgeGroupListLoading: false,
};

const ageGroupSlice = createSlice({
  name: 'ageGroup',
  initialState: initialState,
  reducers: {
    setageGroupDetails: (state, action) => {
      state.ageGroupDetails = action.payload;
    },
    setAgeGroupList: (state, action) => {
      state.ageGroupList = action.payload;
    },
    setIsAgeGroupListLoading: (state, action) => {
      state.isAgeGroupListLoading = action.payload;
    },
  },
});

export const {setageGroupDetails, setAgeGroupList, setIsAgeGroupListLoading} =
  ageGroupSlice.actions;

export default ageGroupSlice.reducer;
