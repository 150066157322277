import {createSlice} from '@reduxjs/toolkit';
import {AdditionalServiceDetailsDto} from 'src/shared/services/cms/additional-service/dto/details-additional-service-dto';

interface AdditionalServiceInitialState {
  additionalServiceDetails?: AdditionalServiceDetailsDto;
  additionalServicesList?: Array<AdditionalServiceDetailsDto>;
  isAdditionalServicesListLoading?: boolean;
}

const initialState: AdditionalServiceInitialState = {
  additionalServiceDetails: undefined,
  additionalServicesList: [],
  isAdditionalServicesListLoading: false,
};

const additionalServiceSlice = createSlice({
  name: 'additionalService',
  initialState: initialState,
  reducers: {
    setAdditionalServiceDetails: (state, action) => {
      state.additionalServiceDetails = action.payload;
    },
    setServicesList: (state, action) => {
      state.additionalServicesList = action.payload;
    },
    setIsAdditionalServicesListLoading: (state, action) => {
      state.isAdditionalServicesListLoading = action.payload;
    },
  },
});

export const {
  setAdditionalServiceDetails,
  setServicesList,
  setIsAdditionalServicesListLoading,
} = additionalServiceSlice.actions;

export default additionalServiceSlice.reducer;
