import {AiOutlineLock, AiOutlineTeam} from 'react-icons/ai';
import {BiCategoryAlt} from 'react-icons/bi';
import {FaUsers} from 'react-icons/fa';
import {FaUsers as ClientsIcon, FaHouseChimneyUser} from 'react-icons/fa6';
import {MdOutlineCases} from 'react-icons/md';

import {
  ManageAdditionalServices,
  ManageAgeGroup,
  ManageBooking,
  ManageCaregivers,
  ManageCareRecipients,
  ManageCategories,
  ManageCertificates,
  ManageClients,
  ManageLanguages,
  ManageRoles,
  ManageServices,
  ManageSubcategory,
  ManageUser,
} from 'src/shared/constants/permissions-enum';
import {FaRegCalendarAlt} from 'react-icons/fa';

const routesConfig = [
  {
    id: 'caregivers',
    title: 'caregivers',
    messageId: 'caregivers.sideBarName',
    icon: <FaUsers />,
    type: 'item',
    path: '/caregivers',
    permissionKey: ManageCaregivers.List,
  },
  {
    id: 'clients',
    title: 'clients',
    messageId: 'clients.sideBarName',
    icon: <ClientsIcon />,
    type: 'item',
    path: '/clients',
    permissionKey: ManageClients.List,
  },
  {
    id: 'care-recipients',
    title: 'care-recipients',
    messageId: 'careRecipients.sideBarName',
    icon: <FaHouseChimneyUser />,
    type: 'item',
    path: '/care-recipients',
    permissionKey: ManageCareRecipients.List,
  },
  {
    id: 'cases',
    title: 'cases',
    messageId: 'cases.sideBarName',
    icon: <MdOutlineCases />,
    type: 'item',
    path: '/cases',
    // permissionKey: ManageCases.List,
    permissionKey: ManageCareRecipients.List,
  },
  {
    id: 'booking',
    title: 'booking',
    messageId: 'booking.sideBarName',
    icon: <FaRegCalendarAlt />,
    type: 'item',
    path: '/booking',
    // permissionKey: ManageBooking.List,
    permissionKey: ManageCareRecipients.List,
  },
  {
    id: 'cms',
    title: 'cms',
    messageId: 'cms.sideBarName',
    icon: <BiCategoryAlt />,
    type: 'group',
    permissionKey: [
      ManageCategories.List,
      ManageSubcategory.List,
      ManageServices.List,
      ManageAdditionalServices.List,
    ],
    children: [
      {
        id: 'categories',
        title: 'categories',
        messageId: 'categories.sideBarName',
        type: 'item',
        path: '/cms/categories',
        permissionKey: ManageCategories.List,
      },
      {
        id: 'subcategory',
        title: 'subcategory',
        messageId: 'subcategory.sideBarName',
        type: 'item',
        path: '/cms/subcategory',
        permissionKey: ManageSubcategory.List,
      },
      {
        id: 'services',
        title: 'services',
        messageId: 'services.sideBarName',
        type: 'item',
        path: '/cms/services',
        permissionKey: ManageServices.List,
      },
      {
        id: 'age-group',
        title: 'age-group',
        messageId: 'ageGroup.sideBarName',
        type: 'item',
        path: '/cms/age-group',
        permissionKey: ManageAgeGroup.List,
      },
      {
        id: 'certificates',
        title: 'certificates',
        messageId: 'certificates.sideBarName',
        type: 'item',
        path: '/cms/certificates',
        permissionKey: ManageCertificates.List,
      },
      {
        id: 'languages',
        title: 'languages',
        messageId: 'languages.sideBarName',
        type: 'item',
        path: '/cms/languages',
        permissionKey: ManageLanguages.List,
      },
      // {
      //   id: 'additional-service',
      //   title: 'additional-service',
      //   messageId: 'additionalService.sideBarName',
      //   type: 'item',
      //   path: '/cms/additional-service',
      //   permissionKey: ManageAdditionalServices.List,
      // },
    ],
  },
  {
    id: 'roles',
    title: 'roles',
    messageId: 'rolesManagement.sideBarName',
    icon: <AiOutlineLock />,
    type: 'item',
    path: '/roles',
    permissionKey: ManageRoles.List,
  },
  {
    id: 'users',
    title: 'users',
    messageId: 'usersManagement.sideBarName',
    icon: <AiOutlineTeam />,
    type: 'item',
    path: '/users',
    permissionKey: ManageUser.List,
  },
];
export default routesConfig;
