import {createSlice} from '@reduxjs/toolkit';
import {DetailsRoleDto} from 'src/shared/services/roles-management/dto/details-role-dto';

interface RolesManagementInitialState {
  rolesList: Array<DetailsRoleDto> | undefined;
  roleDetails: DetailsRoleDto | undefined;
  isRolesListLoading: boolean | undefined;
}

const initialState: RolesManagementInitialState = {
  rolesList: [],
  roleDetails: undefined,
  isRolesListLoading: false,
};

const rolesManagementSlice = createSlice({
  name: 'rolesManagement',
  initialState: initialState,
  reducers: {
    setRolesList: (state, action) => {
      state.rolesList = action.payload;
    },
    setRoleDetails: (state, action) => {
      state.roleDetails = action.payload;
    },
    setIsRolesListLoading: (state, action) => {
      state.isRolesListLoading = action.payload;
    },
  },
});

export const {setRolesList, setRoleDetails, setIsRolesListLoading} =
  rolesManagementSlice.actions;

export default rolesManagementSlice.reducer;
