import {createSlice} from '@reduxjs/toolkit';
import {CareRecipientDetailsDto} from 'src/shared/services/care-recipients/dto/details-care-recipient-dto';

interface CareRecipientsInitialState {
  careRecipientDetails: CareRecipientDetailsDto | undefined;
}

const initialState: CareRecipientsInitialState = {
  careRecipientDetails: undefined,
};

const careRecipientsSlice = createSlice({
  name: 'careRecipients',
  initialState: initialState,
  reducers: {
    setCareRecipientDetails: (state, action) => {
      state.careRecipientDetails = action.payload;
    },
  },
});

export const {setCareRecipientDetails} = careRecipientsSlice.actions;

export default careRecipientsSlice.reducer;
