import {createSlice} from '@reduxjs/toolkit';
import {BookingDetailsDto} from 'src/shared/services/booking/dto/details-booking-dto';

interface BookingInitialState {
  bookingDetails: BookingDetailsDto | undefined;
}

const initialState: BookingInitialState = {
  bookingDetails: undefined,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialState,
  reducers: {
    setBookingDetails: (state, action) => {
      state.bookingDetails = action.payload;
    },
  },
});

export const {setBookingDetails} = bookingSlice.actions;

export default bookingSlice.reducer;
