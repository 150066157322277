import React from 'react';

// Categories
const Categories = React.lazy(() => import('./categories'));
const CreateOrUpdateCategory = React.lazy(
  () => import('./categories/create-or-update'),
);

// Subcategory
const Subcategory = React.lazy(() => import('./subcategory'));
const CreateOrUpdateSubcategory = React.lazy(
  () => import('./subcategory/create-or-update'),
);

// Services
const Services = React.lazy(() => import('./services'));
const CreateOrUpdateService = React.lazy(
  () => import('./services/create-or-update'),
);

// Age Group
const AgeGroup = React.lazy(() => import('./age-groups'));
const CreateOrUpdateAgeGroup = React.lazy(
  () => import('./age-groups/create-or-update'),
);

// AdditionalService
const AdditionalService = React.lazy(() => import('./additional-service'));
const CreateOrUpdateAdditionalService = React.lazy(
  () => import('./additional-service/create-or-update'),
);

// Certificates
const Certificates = React.lazy(() => import('./certificate'));
const CreateOrUpdateCertificate = React.lazy(
  () => import('./certificate/create-or-update'),
);

// Languages
const Languages = React.lazy(() => import('./languages'));
const CreateOrUpdateLanguage = React.lazy(
  () => import('./languages/create-or-update'),
);

export const cmsConfig = [
  {
    path: '/cms/categories',
    element: <Categories />,
  },
  {
    path: '/cms/categories/create',
    element: <CreateOrUpdateCategory />,
  },
  {
    path: '/cms/categories/update/:id',
    element: <CreateOrUpdateCategory />,
  },
  {
    path: '/cms/subcategory',
    element: <Subcategory />,
  },
  {
    path: '/cms/subcategory/create',
    element: <CreateOrUpdateSubcategory />,
  },
  {
    path: '/cms/subcategory/update/:id',
    element: <CreateOrUpdateSubcategory />,
  },
  {
    path: '/cms/services',
    element: <Services />,
  },
  {
    path: '/cms/services/create',
    element: <CreateOrUpdateService />,
  },
  {
    path: '/cms/services/update/:id',
    element: <CreateOrUpdateService />,
  },
  {
    path: '/cms/certificates',
    element: <Certificates />,
  },
  {
    path: '/cms/certificates/create',
    element: <CreateOrUpdateCertificate />,
  },
  {
    path: '/cms/certificates/update/:id',
    element: <CreateOrUpdateCertificate />,
  },
  {
    path: '/cms/languages',
    element: <Languages />,
  },
  {
    path: '/cms/languages/create',
    element: <CreateOrUpdateLanguage />,
  },
  {
    path: '/cms/languages/update/:id',
    element: <CreateOrUpdateLanguage />,
  },

  {
    path: '/cms/age-group',
    element: <AgeGroup />,
  },
  {
    path: '/cms/age-group/create',
    element: <CreateOrUpdateAgeGroup />,
  },
  {
    path: '/cms/age-group/update/:id',
    element: <CreateOrUpdateAgeGroup />,
  },

  {
    path: '/cms/additional-service',
    element: <AdditionalService />,
  },
  {
    path: '/cms/additional-service/create',
    element: <CreateOrUpdateAdditionalService />,
  },
  {
    path: '/cms/additional-service/update/:id',
    element: <CreateOrUpdateAdditionalService />,
  },
];
