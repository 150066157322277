import urlJoin from 'url-join';
import {ScheduleType} from './AppEnums';

export const initialUrl = '/'; // this url will open after login
export const AUTH_TOKEN: string = 'app-auth-token-admin';
export const REFRESH_TOKEN_KEY = 'app-refresh-token-admin';
export const TOKEN_EXPIRE_DATE = 'app-auth-expire-admin';
export const USER_INFO: string = 'user-info';
export const COUNT_NOTIFICATIONS: string = 'count-notifications';
export const MAX_AGE = 60 * 60 * 24 * 1;
export const Default_Language: string = 'app-language';
export const FCM_TOKEN_KEY = 'fcm-token';
export const LANGUAGE_HEADER_KEY = 'language-key';
export const PERMISSIONS_KEY = 'permissions';

export const PrimaryColor = '#21aa95';
export const SidebarColor = '#fff';
export const SidebarBackgroundColor = '#fff';
export const UPLOAD_ICON_VALIDATE = [
  'image/svg+xml',
  'image/vnd.microsoft.icon',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/webp',
];

export const formatDateTime: string = 'YYYY-MM-DD hh:mm A';
export const formatDateWithSecondsTime: string = 'YYYY-MM-DD hh:mm:ss A';
export const formatDate: string = 'MMM D, YYYY';
export const formatTimeWithMonthAndDay: string = 'MMM DD hh:mm a';
export const formatTime: string = 'hh:mm a';

export const passwordRegex: any =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{12,}$/;

export const emailRegex: any =
  /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const countryCodeDefault = '+961';

export const remoteServiceBaseUrl = process.env.REACT_APP_PUBLIC_BASE;

export const remoteServiceBaseApiUrl = urlJoin(
  `${process.env.REACT_APP_PUBLIC_API_BASE}`,
);

export const remoteSocketIoUrl = process.env.REACT_APP_REMOTE_SOCKETIO_BASE_URL;

export const remoteServiceBaseDomain = process.env.REACT_APP_PUBLIC_DOMAIN;

export const remoteServiceBaseUrlFile = process.env.REACT_APP_BASE_URL_FILE;

export const PRODUCT_NUMBER_IN_ONE_PAGE = 10;
export const PAGE_NUMBER = 1;
export const DISTANCE_DEFAULT = 10;

export const apiKeyGoogleMap = 'AIzaSyD9SMar9SJqiAdkqiyQzTMyCBnQLzSocoo';

export const INDEX_PAGE_SIZE_DEFAULT = 10;
export const INDEX_PAGE_SIZE_OPTIONS = ['10', '20', '30', '40'];

export const UPLOAD_PICTURE_VALIDATE = [
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/webp',
];
export const UPLOAD_FILE_VALIDATE = ['text/plain', 'application/pdf'];

export const CUSTOMER_ROLES = {
  Administrators: 'Administrators',
};

export const FilterOperators = {
  Equals: '==',
  NotEquals: '!=',
  Contains: '@=',
  StringContains: '@=*',
};

export const STEPS_NUMBERS = {
  One: 0,
  Two: 1,
  Three: 2,
  Four: 3,
  Five: 4,
  Six: 5,
  Seven: 6,
  Eight: 7,
  Nine: 8,
  Ten: 9,
  Eleven: 10,
  Twelve: 11,
  Thirteen: 12,
  Fourteen: 13,
  Fifteen: 14,
};

export const GENDER_TYPE = [
  {
    value: 1,
    label: 'male',
  },
  {
    value: 2,
    label: 'female',
  },
  {
    value: 3,
    label: 'noPreference',
  },
];

export const RELATIVITY = [
  {
    value: 1,
    label: 'parent',
  },
  {
    value: 2,
    label: 'mySelf',
  },
  {
    value: 3,
    label: 'spouse',
  },
  {
    value: 4,
    label: 'child',
  },
  {
    value: 5,
    label: 'other',
  },
];

export const WAYS_REFER = {
  Facebook: 'facebook',
  Instagram: 'instagram',
  Twitter: 'twitter',
  Other: 'other',
};

export const CASE_DETAILS_SECTION_TABS = [
  {
    value: STEPS_NUMBERS.One,
    label: 'services',
  },
  {
    value: STEPS_NUMBERS.Two,
    label: 'location',
  },
  {
    value: STEPS_NUMBERS.Three,
    label: 'schedule',
  },
  {
    value: STEPS_NUMBERS.Four,
    label: 'budget',
  },
  {
    value: STEPS_NUMBERS.Five,
    label: 'notes',
  },
  {
    value: STEPS_NUMBERS.Six,
    label: 'preferences',
  },
  {
    value: STEPS_NUMBERS.Seven,
    label: 'caseSummary',
  },
];

export const DAYS_OF_WEEK_NAMES = {
  Monday: 'common.monday',
  Tuesday: 'common.tuesday',
  Wednesday: 'common.wednesday',
  Thursday: 'common.thursday',
  Friday: 'common.friday',
  Saturday: 'common.saturday',
  Sunday: 'common.sunday',
};

export const SCHEDULE_TYPE = [
  {
    value: ScheduleType.regular,
    label: 'cases.regular',
  },
  {
    value: ScheduleType.oneVisit,
    label: 'cases.oneVisit',
  },
  {
    value: ScheduleType.variable,
    label: 'cases.variable',
  },
];

export const CURRENCY = [
  {
    value: 'USD',
    label: 'USD',
  },
];

export const GENERAL_PREFERENCES = {
  isHasACar: 'Have a car',
  isHasAMotorcycle: 'Have a motorcycle',
  isNonSmoker: 'Non-smoker',
  isCatFriendly: 'Cat friendly',
  isDogFriendly: 'Dog friendly',
};

export const AGE_RANGE = {
  UpTo24: 'Up to 24',
  From24To35: '24 - 35',
  From35To50: '35 - 50',
  From50ToAbove: '50 and above',
};
