import {createSlice} from '@reduxjs/toolkit';

export interface PositionGoogleMapProps {
  lat: number;
  lng: number;
  zipCode?: any;
}
interface GoogleMapInitialState {
  markerCenter: PositionGoogleMapProps;
  marker: PositionGoogleMapProps;
  markerList: Array<PositionGoogleMapProps>;
}

const initialState: GoogleMapInitialState = {
  markerCenter: {
    lat: -33.447487,
    lng: -70.673676,
  },
  marker: {
    lat: -33.447487,
    lng: -70.673676,
  },
  markerList: [],
};

const googleMapSlice = createSlice({
  name: 'googleMap',
  initialState: initialState,
  reducers: {
    setMarkerCenter: (state, action) => {
      state.markerCenter = action.payload;
    },
    setMarker: (state, action) => {
      state.marker = action.payload;
    },
    setMarkerList: (state, action) => {
      state.markerList = action.payload;
    },
  },
});

export const {setMarkerCenter, setMarker, setMarkerList} =
  googleMapSlice.actions;

export default googleMapSlice.reducer;
