import {createSlice} from '@reduxjs/toolkit';
import {SubCategoryDetailsDto} from 'src/shared/services/cms/subcategory/dto/details-subcategory-dto';

interface SubcategoryInitialState {
  subcategoryDetails?: SubCategoryDetailsDto;
  subcategoryList?: Array<SubCategoryDetailsDto>;
  isSubcategoryListLoading?: boolean;
}

const initialState: SubcategoryInitialState = {
  subcategoryDetails: undefined,
  subcategoryList: [],
  isSubcategoryListLoading: false,
};

const subcategorySlice = createSlice({
  name: 'subcategory',
  initialState: initialState,
  reducers: {
    setSubcategoryDetails: (state, action) => {
      state.subcategoryDetails = action.payload;
    },
    setSubcategoryList: (state, action) => {
      state.subcategoryList = action.payload;
    },
    setIsSubcategoryListLoading: (state, action) => {
      state.isSubcategoryListLoading = action.payload;
    },
  },
});

export const {
  setSubcategoryDetails,
  setSubcategoryList,
  setIsSubcategoryListLoading,
} = subcategorySlice.actions;

export default subcategorySlice.reducer;
