import ApiService from 'src/shared/utils/base-api/api-service';
import {SigninRequestDto} from './dto/signin-request-dto';
import {SigninResponseDto} from './dto/signin-response-dto';
import {ResponseDto} from 'src/shared/common-dtos/response-dto';
import {DetailsRoleDto} from '../roles-management/dto/details-role-dto';
import {RefreshTokenParamsDto} from './dto/refresh-token-params-dto';

class AuthService extends ApiService {
  constructor() {
    super({
      baseURL: '/identity/Auth',
    });
  }
  public signin(
    input: SigninRequestDto,
  ): Promise<ResponseDto<SigninResponseDto>> {
    return this.post('/Login', input);
  }

  public getMyRoles(): Promise<ResponseDto<Array<DetailsRoleDto>>> {
    return this.get('/GetMyRoles');
  }

  public refreshToken(
    input?: RefreshTokenParamsDto,
  ): Promise<ResponseDto<SigninResponseDto>> {
    return this.post('/Refresh', input);
  }
}

export const authService = new AuthService();
