import {createSlice} from '@reduxjs/toolkit';
import {ServiceDetailsDto} from 'src/shared/services/cms/services/dto/service-details-dto';

interface ServicesInitialState {
  serviceDetails?: ServiceDetailsDto;
  servicesList?: Array<ServiceDetailsDto>;
  isServicesListLoading?: boolean;
}

const initialState: ServicesInitialState = {
  serviceDetails: undefined,
  servicesList: [],
  isServicesListLoading: false,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState: initialState,
  reducers: {
    setServiceDetails: (state, action) => {
      state.serviceDetails = action.payload;
    },
    setServicesList: (state, action) => {
      state.servicesList = action.payload;
    },
    setIsServicesListLoading: (state, action) => {
      state.isServicesListLoading = action.payload;
    },
  },
});

export const {setServiceDetails, setServicesList, setIsServicesListLoading} =
  servicesSlice.actions;

export default servicesSlice.reducer;
