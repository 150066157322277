import {createSlice} from '@reduxjs/toolkit';
import {UserDetailsDto} from 'src/shared/services/users-management/dto/details-user-dto';

interface UsersManagementInitialState {
  userDetails: UserDetailsDto | undefined;
  isOpenUpdateUserRolesModal?: boolean;
}

const initialState: UsersManagementInitialState = {
  userDetails: undefined,
  isOpenUpdateUserRolesModal: false,
};

const usersManagementSlice = createSlice({
  name: 'usersManagement',
  initialState: initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setIsOpenUpdateUserRolesModal: (state, action) => {
      state.isOpenUpdateUserRolesModal = action.payload;
    },
  },
});

export const {setUserDetails, setIsOpenUpdateUserRolesModal} =
  usersManagementSlice.actions;

export default usersManagementSlice.reducer;
