import {ClientDetailsDto} from 'src/shared/services/clients/dto/details-client-dto';
import {STEPS_NUMBERS} from './../../../../shared/constants/AppConst';
import {createSlice} from '@reduxjs/toolkit';

interface ClientsInitialState {
  clientDetails: ClientDetailsDto | undefined;
  currentStepClient: number;
  clientsList?: Array<ClientDetailsDto>;
  isClientsListLoading?: boolean;
}

const initialState: ClientsInitialState = {
  clientDetails: undefined,
  currentStepClient: STEPS_NUMBERS.One,
  clientsList: undefined,
  isClientsListLoading: false,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState: initialState,
  reducers: {
    setClientDetails: (state, action) => {
      state.clientDetails = action.payload;
    },
    setCurrentStepClient: (state, action) => {
      state.currentStepClient = action.payload;
    },
    setClientsList: (state, action) => {
      state.clientsList = action.payload;
    },
    setIsClientsListLoading: (state, action) => {
      state.isClientsListLoading = action.payload;
    },
  },
});

export const {
  setClientDetails,
  setCurrentStepClient,
  setClientsList,
  setIsClientsListLoading,
} = clientsSlice.actions;

export default clientsSlice.reducer;
