import {STEPS_NUMBERS} from './../../../../shared/constants/AppConst';
import {createSlice} from '@reduxjs/toolkit';
import {CaregiverDetailsDto} from 'src/shared/services/caregivers/dto/details-caregiver-dto';

interface CaregiversInitialState {
  caregiverDetails: CaregiverDetailsDto | undefined;
  currentStepCaregiver: number;
}

const initialState: CaregiversInitialState = {
  caregiverDetails: undefined,
  currentStepCaregiver: STEPS_NUMBERS.One,
};

const caregiversSlice = createSlice({
  name: 'caregivers',
  initialState: initialState,
  reducers: {
    setCaregiverDetails: (state, action) => {
      state.caregiverDetails = action.payload;
    },
    setCurrentStepCaregiver: (state, action) => {
      state.currentStepCaregiver = action.payload;
    },
  },
});

export const {setCaregiverDetails, setCurrentStepCaregiver} =
  caregiversSlice.actions;

export default caregiversSlice.reducer;
