import {createSlice} from '@reduxjs/toolkit';
import {STEPS_NUMBERS} from 'src/shared/constants/AppConst';
import {CaseDetailsDto} from 'src/shared/services/cases/dto/details-case-dto';

interface CasesInitialState {
  caseDetails: CaseDetailsDto | undefined;
  currentTabInCaseDetails: number;
  currentTabInSchedule: number;
}

const initialState: CasesInitialState = {
  caseDetails: undefined,
  currentTabInCaseDetails: STEPS_NUMBERS.Six,
  currentTabInSchedule: STEPS_NUMBERS.One,
};

const casesSlice = createSlice({
  name: 'cases',
  initialState: initialState,
  reducers: {
    setCaseDetails: (state, action) => {
      state.caseDetails = action.payload;
    },
    setCurrentTabInCaseDetails: (state, action) => {
      state.currentTabInCaseDetails = action.payload;
    },
    setCurrentTabInSchedule: (state, action) => {
      state.currentTabInSchedule = action.payload;
    },
  },
});

export const {
  setCaseDetails,
  setCurrentTabInCaseDetails,
  setCurrentTabInSchedule,
} = casesSlice.actions;

export default casesSlice.reducer;
