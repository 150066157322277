import {createSlice} from '@reduxjs/toolkit';
import {CertificateDetailsDto} from 'src/shared/services/cms/certificate/dto/details-certificate-dto';

interface CertificatesInitialState {
  certificateDetails?: CertificateDetailsDto;
  certificatesList?: Array<CertificateDetailsDto>;
  isCertificatesListLoading?: boolean;
}

const initialState: CertificatesInitialState = {
  certificateDetails: undefined,
  certificatesList: [],
  isCertificatesListLoading: false,
};

const certificatesSlice = createSlice({
  name: 'certificates',
  initialState: initialState,
  reducers: {
    setCertificateDetails: (state, action) => {
      state.certificateDetails = action.payload;
    },
    setCertificatesList: (state, action) => {
      state.certificatesList = action.payload;
    },
    setIsCertificatesListLoading: (state, action) => {
      state.isCertificatesListLoading = action.payload;
    },
  },
});

export const {
  setCertificateDetails,
  setCertificatesList,
  setIsCertificatesListLoading,
} = certificatesSlice.actions;

export default certificatesSlice.reducer;
